<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <!-- 头部面包屑 -->
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">协议审批管理</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">协议签章及归档列表</a>
                </span>
            </div>
            <!-- 条件查询区域和列表区域 -->
            <div class="framePage-body">
                <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
                    <!-- 搜索区域 -->
                    <div class="searchbox" style="margin-bottom: 15px">
                        <div title="协议类型" class="searchboxItem">
                            <span class="itemLabel">协议类型:</span>
                            <el-select clearable placeholder="请选择协议类型" size="small" v-model="searchForm.protocolType">
                                <el-option v-for="item in protocolTypeOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div title="签订机构" class="searchboxItem">
                            <span class="itemLabel">签订机构:</span>
                            <el-select size="small" v-model="searchForm.signCompId" remote :remote-method="getCompanyList"
                                filterable clearable placeholder="请至少输入两个字搜索">
                                <el-option v-for="item in companyList" :key="item.compId" :label="item.compName"
                                    :value="item.compId"></el-option>
                            </el-select>
                        </div>
                        <div title="签订日期" class="searchboxItem">
                            <span class="itemLabel">签订日期:</span>
                            <el-date-picker size="small" v-model="searchForm.qdDate" type="daterange"
                                value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                        <div title="档案编号" class="searchboxItem">
                            <span class="itemLabel">档案编号:</span>
                            <el-input size="small" clearable type="text" placeholder="请输入档案编号"
                                v-model="searchForm.certNo" />
                        </div>
                    </div>
                    <div class="searchbox">
                        <div title="行政区划" class="searchboxItem ci-full">
                            <span class="itemLabel">行政区划:</span>
                            <el-cascader clearable filterable v-model="searchForm.areaId" :options="areatreeList"
                                :props="propsarea" size="small"></el-cascader>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(true)">查询</el-button>
                        </div>
                    </div>
                </div>

                <!-- 列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table :data="listData" :height="tableHeight" size="small" tooltip-effect="dark"
                            style="width: 100%" :header-cell-style="tableHeader" stripe>
                            <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="50" />
                            <el-table-column label="协议类型" align="left" show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    {{ $setDictionary("PROTOCOL_TYPE", row.protocolType) }}
                                </template>
                            </el-table-column>
                            <el-table-column min-width="150" label="签订机构" align="left" prop="compName"
                                show-overflow-tooltip />
                            <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaNamePath"
                                min-width="220px" />
                            <el-table-column min-width="100" label="签订日期" align="left" prop="signDate"
                                show-overflow-tooltip />
                            <el-table-column label="对接业务员" align="left" prop="salesman" show-overflow-tooltip />
                            <el-table-column min-width="100" label="协议状态" align="left" prop="processValue"
                                show-overflow-tooltip />
                            <el-table-column min-width="100" label="报备日期" align="left" prop="filingDate"
                                show-overflow-tooltip />
                            <el-table-column label="审核" align="left" show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    <span>{{ row.reviewer }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="审批" align="left" show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    <span>{{ row.approver }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="签章类型" align="left" show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    {{ $setDictionary("SIGNATURE_TYPE", row.signatureType) }}
                                </template>
                            </el-table-column>

                            <el-table-column label="签章日期" align="left" prop="signatureDate" show-overflow-tooltip />
                            <el-table-column label="档案编号" align="left" prop="certNo" show-overflow-tooltip />
                            <el-table-column label="归档日期" align="left" prop="archiveDate" show-overflow-tooltip />
                            <el-table-column label="备注" align="left" show-overflow-tooltip prop="remark" min-width="150" />
                            <el-table-column label="操作" align="left" prop="department" show-overflow-tooltip width="130"
                                fixed="right">
                                <template v-slot="{ row }">
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="see(row)">
                                        查看
                                    </el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="signature(row, 0)">
                                        <!--                                  :disabled="signatureBtn(row.auditNode,0)"-->
                                        签章
                                    </el-button>
                                    <!-- <el-button 
                                    type="text" size="mini" 
                                    style="padding: 0 5px;" 
                                    @click="signature(row,1)"
                                    :disabled="signatureBtn(row.auditNode,1)"
                                >
                                    归档
                                </el-button> -->
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="signature(row, 1)">
                                        归档
                                    </el-button>
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <!-- 分页器 -->
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange" />
            </div>
        </div>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" @closed="dialogClosed">
            <el-form v-if="signatureOrFile === 0" ref="signatureFormRef" :model="signatureFormData" :rules="signatureRule"
                label-width="100px">
                <el-form-item label="协议类型：" label-width="100px" prop="protocolType">
                    <el-select size="small" v-model="signatureFormData.protocolType" clearable disabled
                        placeholder="请选择协议类型" style="width:100%;">
                        <el-option v-for="item in protocolTypeOptions" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="签章类型：" prop="signatureType" key="signatureType">
                    <el-select v-model="signatureFormData.signatureType" clearable size="small" style="width:100%;"
                        placeholder="请选择签章类型">
                        <el-option v-for="item in signatureOptions" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="签章日期：" prop="signatureDate" key="signatureDate">
                    <el-date-picker clearable size="small" v-model="signatureFormData.signatureDate" type="date"
                        placeholder="选择签章日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="备注：">
                    <el-input size="small" show-word-limit maxlength="200" type="textarea" resize="none" rows="3" clearable
                        v-model="signatureFormData.remark">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form v-else :model="fileFormData" ref="fileRef" :rules="fileRule" label-width="80px">
                <el-form-item label="档案编号" prop="certNo" key="certNo">
                    <el-input size="small" placeholder="请输入档案编号" clearable v-model="fileFormData.certNo"></el-input>
                </el-form-item>
                <el-form-item label="归档日期" prop="archiveDate" key="archiveDate">
                    <el-date-picker clearable size="small" v-model="fileFormData.archiveDate" type="date"
                        placeholder="选择签章日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input size="small" show-word-limit maxlength="200" type="textarea" resize="none" rows="3" clearable
                        v-model="fileFormData.remark">
                    </el-input>
                </el-form-item>
            </el-form>
            <div style="margin-bottom: 20px;font-size:15px;color:#606266;padding-left:10px;">审核记录</div>
            <el-table :header-cell-style="tableHeader" height="19rem" :data="auditRecords" size="small"
                tooltip-effect="dark" style="width: 100%;" stripe>
                <el-table-column label="序号" align="center" type="index" width="50" />
                <el-table-column label="操作内容" align="left" min-width="130">
                    <template v-slot="{ row }">
                        {{ processResult(row.processCode) }}
                    </template>
                </el-table-column>
                <el-table-column label="操作人" align="left" prop="fullname" />
                <el-table-column label="操作日期" align="left" prop="createTime" min-width="140" />
                <el-table-column label="备注" align="left" prop="remark" min-width="130" />
                <el-table-column label="操作" align="center" width="180px" fixed="right" v-if="signatureOrFile === 0">
                    <div slot-scope="scope">
                        <el-button type="text" size="mini" style="padding: 0 5px"
                            @click="editSignatureRecordDesc(scope.row)" :disabled="scope.row.processCode != '80'">
                            修改
                        </el-button>
                        <el-button type="text" size="mini" style="padding: 0 5px" @click="deleteSignatureRecord(scope.row)"
                            :disabled="scope.row.processCode != '80'">
                            删除
                        </el-button>
                    </div>
                </el-table-column>
            </el-table>
            <span slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 认</el-button>
            </span>
        </el-dialog>
        <el-dialog title="修改签章审核记录" :visible.sync="editSignatureVisible" @close="closeModelSignature">
            <el-form :model="recordForm" style="width: 100%" :rules="recordRules" ref="recordForm">
                <el-form-item label="备注：" label-width="100px" prop="remark">
                    <el-input type="textarea" v-model="recordForm.remark"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer flexcc">
                <el-button @click="closeModelSignature">取 消</el-button>
                <el-button class="bgc-bv" type="primary" @click="recordSave">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
    name: "agreementSignature",
    components: {
        Empty,
        PageNum
    },
    mixins: [List],
    created() {
        // 处理协议类型字典数据
        const protocolType = this.$setDictionary("PROTOCOL_TYPE", "list");
        Object.keys(protocolType).forEach((key) => {
            this.protocolTypeOptions.push({
                label: protocolType[key],
                value: key
            });
        });
        this.getListData();
        this.processList();
        this.timeFormat();
        this.getareatree();
    },
    data() {
        return {
            // 协议列表数据
            listData: [],
            // 查询条件表单
            searchForm: {
                protocolType: "", //协议类型
                compName: "",// 签订机构
                qdDate: [], // 签订日期
                certNo:"",// 归档编号
                areaId:"", // 行政区划
            },
            // 分页器数据
            pagination: {
                total: 0,
                size: 10,
                current: 1
            },
            approvalStatus: [],
            // 协议类型
            protocolTypeOptions: [],
            // 签订机构数据
            companyList: [],
            // 经办人数据
            operatorList: [],
            // 行政区划
            areatreeList: [], 
            // 行政区划 - 数据格式
            propsarea: {
                value: "value",
                label: "label",
                emitPath: false,
                checkStrictly: true,
            },
            // 签章或归档对话框可见性
            dialogVisible: false,
            // 对话框标题
            dialogTitle: "",
            // 对话框数据
            auditRecords: [], // 审核记录
            // 签章表单
            signatureFormData: {
                protocolId: "",// 协议Id
                signatureType: "", // 签章类型 10电子 20红章
                protocolType: "", // 协议类型
                signatureDate: "", // 签章日期
                remark: "", // 备注
            },
            // 归档表单数据
            fileFormData: {
                protocolId: "", // 协议id
                certNo: "", // 归档编号
                archiveDate: "",// 归档日期
                remark: "", // 备注
            },
            // 判断是签章还是归档 0签章 1归档
            signatureOrFile: 0,
            // 签章类型数据
            signatureOptions: [
                {
                    label: "电子章",
                    value: "10"
                },
                {
                    label: "合同章",
                    value: "20"
                },
                {
                    label: "公章",
                    value: "30"
                },
                {
                    label: "合同章+法人章",
                    value: "40"
                }
            ],
            // 签章表单规则
            signatureRule: {
                signatureType: [
                    { required: true, message: "请选择签章类型", trigger: "change" }
                ],
                protocolType: [
                    { required: true, message: "请选择协议类型", trigger: "change" }
                ],
                signatureDate: [
                    { required: true, message: "请选择签章日期", trigger: "blur" }
                ]
            },
            fileRule: {
                certNo: [
                    { required: true, message: "请输入档案编号", trigger: "blur" }
                ],
                archiveDate: [
                    { required: true, message: "请选择归档日期", trigger: "blur" }
                ]
            },
            // 当前日期 "2022-8-9"
            dateNow: "",

            editSignatureVisible: false,
            recordForm: {
                remark: "",
            },
            recordRules: {
                remark: [
                    { required: true, message: "请输入备注", trigger: "blur" },
                ]
            },
        }
    },
    methods: {
        // 获取行政区划
        getareatree() {
            this.$post("/sys/area/tree").then((ret) => {
                this.areatreeList = ret.data;
            });
        },
        // 修改签章审核记录备注
        editSignatureRecordDesc(row) {
            this.editSignatureVisible = true;
            this.recordForm = {
                ...row
            }
            console.log(this.recordForm);
        },
        //保存修改记录
        recordSave() {
            console.log(this.recordForm);
            this.$refs['recordForm'].validate((valid) => {
                if (valid) {
                    const params = {
                        ...this.recordForm
                    };
                    this.$post(
                        "/biz/protocol/audit/signatureModify",
                        params
                    ).then((ret) => {
                        this.$message({
                            message: "修改成功",
                            type: "success",
                        });
                        this.getAuditRecord(this.protocolId);
                        // this.signatureOrSealFiling(this.recordForm.contractId,'signature',this.recordForm)
                        this.closeModelSignature();
                    });
                }
            });
        },
        // 关闭修改签章审核记录备注
        closeModelSignature() {
            this.recordForm = {
                remark: "",
            };
            this.editSignatureVisible = false;
        },
        // 删除签章审核记录
        deleteSignatureRecord(row) {
            this.$confirm('确定删除此条审核记录吗', "提示", {
                confirmButtonText: "确定删除",
                cancelButtonText: "取消",
                confirmButtonClass: "bgc-bv",
            }).then(() => {
                let parmar = {
                    ...row
                }
                this.$post("/biz/protocol/audit/signatureDelete", parmar)
                    .then((ret) => {
                        if (ret.status == "0") {
                            this.$message({
                                message: "删除成功",
                                type: "success",
                            })
                            this.getAuditRecord(this.protocolId);
                        }
                    })
                    .catch((err) => { });
            });
        },
        // 获取当前日期
        timeFormat() {
            const d = new Date();
            const year = d.getFullYear();
            let month = d.getMonth() + 1;
            let date = d.getDate();
            if (month < 10) month = "0" + month;
            if (date < 10) date = "0" + date;
            this.dateNow = year + "-" + month + "-" + date;
        },
        // 获取协议列表
        getListData(isQuery) {
            const { searchForm, pagination } = this;
            if (isQuery) pagination.current = 1;
            // 定义查询条件
            const condition = {};
            // 合并分页器数据
            condition["pageNum"] = pagination.current;
            condition["pageSize"] = pagination.size;
            // 如果有签订日期，则进行处理
            if (searchForm.qdDate) {
                condition["signStartDate"] = searchForm.qdDate[0];
                condition["signEndDate"] = searchForm.qdDate[1];
            }
            // 合并查询数据
            Object.keys(searchForm).forEach((key) => {
                if (searchForm[key]) condition[key] = searchForm[key];
            });
            this.$post("/biz/protocol/pageList", condition).then(res => {
                this.listData = res.data.list || [];
                pagination.total = res.data.total || 0;
            }).catch(e => {
                return e;
            });
        },
        // 分页器当前页改变
        currentChange(val) {
            const { pagination } = this;
            pagination.current = val;
            this.getListData();
        },
        // 分页器条数改变
        sizeChange(val) {
            const { pagination } = this;
            pagination.size = val;
            this.getListData();
        },
        // 获取签订机构数据
        getCompanyList(query) {
            if (query.trim().length >= 2) {
                this.$post("/sys/company/queryCompanyList", {
                    compName: query.trim()
                }).then((res) => {
                    this.companyList = res.data || [];
                }).catch(() => {
                    return;
                });
            } else {
                this.companyList = [];
            }
        },
        // 获取审核流程状态
        processList() {
            this.$post("/biz/audit/templateProcess/processList", {
                templateKey: "FD_PROTOCOL_AUDIT_TYPE"
            }).then(res => {
                this.approvalStatus = res.data || [];
                // this.approvalStatus.forEach(item=>{
                //     console.log(item.processCode,item.processValue);
                // });
            });
        },
        // 签章按钮状态
        signatureBtn(auditNode, type) {
            if (type === 0) {
                const arr = ["70", "80", "90"];
                if (!arr.includes(auditNode)) return true;
                return false;
            } else {
                const arr = ["70", "90"];
                if (!arr.includes(auditNode)) return true;
                return false;
            }
        },
        // 
        // 返回协议结果
        processResult(processCode) {
            let processValue = "";
            this.approvalStatus.forEach(item => {
                if (item.processCode == processCode) processValue = item.processValue;
            });
            return processValue;
        },
        // 获取审核记录
        getAuditRecord(protocolId) {
            this.$post("/biz/audit/process/history/pageList", { resourceId: protocolId }).then(res => {
                this.auditRecords = res.data || [];
            });
        },
        // 签章或者归档
        signature(row, type) {
            const { protocolId, protocolType } = row;
            this.protocolId = row.protocolId
            this.signatureOrFile = type;
            // 获取审核记录
            this.getAuditRecord(this.protocolId)
            if (type === 0) {
                // 签章
                this.dialogTitle = "签章";
                this.signatureFormData.protocolId = protocolId;
                this.signatureFormData.protocolType = protocolType;
                this.signatureFormData.signatureDate = this.dateNow;
                // 回显数据
                this.$post("/biz/protocol/audit/signatureEcho", { protocolId }).then(res => {
                    console.log(res.data);
                    if (res.data) {
                        this.signatureFormData.remark = res.data.remark;
                        this.signatureFormData.signatureType = res.data.signatureType;
                        this.signatureFormData.signatureDate = res.data.signatureDate.replaceAll("/", "-");
                    }
                });
            } else {
                // 归档
                this.dialogTitle = "归档";
                this.fileFormData.protocolId = protocolId;
                this.fileFormData.archiveDate = this.dateNow;
                this.$post("/biz/protocol/audit/archiveEcho", { protocolId }).then(res => {
                    if (res.data) {
                        this.fileFormData.archiveDate = res.data.archiveDate.replaceAll("/", "-");
                        this.fileFormData.certNo = res.data.certNo;
                        this.fileFormData.remark = res.data.remark;
                    }

                });
            }
            this.dialogVisible = true;
        },
        // 对话框关闭后
        dialogClosed() {
            // 清空对话框数据
            this.auditRecords = []; // 清空审核记录
            // 清除表单认证规则
            if (this.$refs.signatureFormRef) this.$refs.signatureFormRef.resetFields();
            if (this.$refs.fileRef) this.$refs.fileRef.resetFields();
            // 清除表单数据
            this.signatureFormData = {
                protocolId: "",// 协议Id
                signatureType: "", // 签章类型 10电子 20红章
                signatureDate: "", // 签章日期
                remark: "", // 备注
            }
            this.fileFormData = {
                protocolId: "", // 协议id
                certNo: "", // 归档编号
                archiveDate: "",// 归档日期
                remark: "", // 备注
            }
        },
        // 对话框确定按钮
        submit() {
            const { signatureOrFile, signatureFormData, fileFormData } = this;
            if (signatureOrFile === 0) {
                // 签章
                this.$refs.signatureFormRef.validate((valid) => {
                    if (valid) {
                        this.$post("/biz/protocol/audit/signature", signatureFormData).then(res => {
                            this.$message.success("操作成功");
                            this.getListData();
                            this.dialogVisible = false;
                        }).catch(e => console.log(e));
                    }
                });
            } else {
                // 归档
                this.$refs.fileRef.validate((valid) => {
                    if (valid) {
                        this.$post("/biz/protocol/audit/archive", fileFormData).then(res => {
                            this.$message.success("操作成功");
                            this.getListData();
                            this.dialogVisible = false;
                        }).catch(e => console.log(e));
                    }
                });
            }
        },
        // 查看
        see(row) {
            const { protocolId } = row;
            this.$router.push({
                path: "/web/agreementAdd",
                query: {
                    protocolId,
                    see: true  // 是否查看
                }
            });
        },
        getTableHeight(opDom = false, page = true) {
            let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 6) * 16;
            if (opDom) tHeight -= 40 + 0.675 * 16 + 1;
            if (page) tHeight -= 32;
            this.tableHeight = tHeight;
        },
    },
    watch: {
        $route(to) {
            if (to.name === "agreementSignature") {
                this.getListData();
            }
        }
    }
}
</script>

<style lang="less">
.searchbox {
    .searchboxItem {
        .el-input {
            input {
                width: 100%;
            }
        }
    }
}

.el-message-box {
    .el-message-box__btns {
        .el-button--primary {
            background-color: #5C6BE8;
            border: none;

            &:hover {
                background-color: #3d4fee;
            }
        }
    }
}

.el-dialog__footer {
    .el-button--primary {
        background-color: #5C6BE8;
        border: none;

        &:hover {
            background-color: #3d4fee;
        }
    }
}

.el-table .el-table__fixed-right::before,
.el-table__fixed::before {
    height: 0 !important;
}
</style>